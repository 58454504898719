.slider-container {
    margin-top: 1rem;
}

.slider-container .range-container .range {
    background: none !important;
    margin-left: 5rem;
    width: calc(100% - 5rem);

    @media (max-width: 380px) {
        width: calc(100% - 3rem) !important;
        margin-left: 3rem;
    }

}


input[type="range"] {
    appearance: none;
    width: 100%;
    border: none !important;
}


@media (min-width: 400px) {

    input[type=range]:focus {
        outline: none;
    }

    input[type=range]::-webkit-slider-runnable-track {
        width: 100%;
        cursor: pointer;
        background: #2F2D5A !important;
        border-radius: 1.3px;
        // height: 0.5rem;
    }

    input[type=range]::-moz-slider-runnable-track {
        width: 100%;
        cursor: pointer;
        background: #2F2D5A !important;
        border-radius: 1.3px;
        // height: 0.5rem;
    }

    input[type=range]::-webkit-slider-thumb {
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        background: #6339FF !important;
        cursor: pointer;
        appearance: none;
        width: 1rem !important;
        height: 1rem !important;
        border-radius: 50%;

        @media (max-width: 400px) {
            transform: scale(1.5);
        }

    }

    input[type=range]::-moz-slider-thumb {
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        background: #6339FF !important;
        cursor: pointer;
        appearance: none;
    }


    input[type=range]:focus::-webkit-slider-runnable-track {
        background: #6339FF;
    }

    input[type=range]:focus::-moz-slider-runnable-track {
        background: #6339FF;
    }
}

@media (max-width: 400px) {
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  
  input[type="range"]:focus {
    outline: none;
  }
  
  input[type="range"]::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  
  $slider-size: 18px;
  $slider-color: #6339FF;
  $track-color: #2F2D5A;

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: $slider-size;
    width: $slider-size;
    background: $slider-color;
    cursor: pointer;
    margin-top: -4px;
  }
  
  input[type="range"]::-moz-range-thumb {
    height: $slider-size;
    width: $slider-size;
    background: $slider-color;
    cursor: pointer;
  }
  
  input[type="range"]::-ms-thumb {
    height: $slider-size;
    width: $slider-size;
    background: $slider-color;
    cursor: pointer;
  }
  /*#############Track##############*/
  
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 12px;
    cursor: pointer;
    background: $track-color;
  }
  
  input[type="range"]:active::-webkit-slider-runnable-track {
    background: $track-color;
  }
  
  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 12px;
    cursor: pointer;
    background: $track-color;
  }
  
  input[type="range"]::-ms-track {
    width: 100%;
    height: 12px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  
  input[type="range"]::-ms-fill-lower {
    background: $track-color;
  }
  
  input[type="range"]:focus::-ms-fill-lower {
    background: $track-color;
  }
  
  input[type="range"]::-ms-fill-upper {
    background: $track-color;
  }
  
  input[type="range"]:focus::-ms-fill-upper {
    background: $track-color;
  }
  
}