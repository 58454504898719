$mainColor: #00C0FF;

// for media query
$mobileL: 425px;        // mobile large width 


.add-btn{
    // padding: 0.6rem 0.8rem;
    text-align: center;
    border: 2px solid $mainColor !important;
    box-sizing: border-box;
    border-radius: 40px;
    display: flex;
    align-items: center;
    background: none;
    cursor: pointer;
    transition: 0.1s ease-in-out;
    text-transform: initial !important;

    &:hover {
        transition: 0.1s ease-in-out;
        background-color: $mainColor;
        * {
            color: white !important;
        }
    }

    &:focus {
        background-color: $mainColor !important;
        * {
            color: white !important;
        }
    }

    i, p{
        line-height: 1.125rem; 
        color: $mainColor;
    }
    p{
        font-size: 1rem;
    }
    i{
        margin-right: 0.5rem;
        font-size: 0.75rem;
    }

}
