// import google poppin font
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$laptopL: 2560px;       // laptop large width
$laptopM: 1440px;       // laptop medium width
$laptop: 1110px;        // laptop width
$tablet: 770px;         // tablet width
$mobileL: 425px;        // mobile large width 
$mobileM: 375px;        // mobile medium width

.chart-container{
    margin-top: 5rem;
    padding: 0;

    .title {
        font-style: normal;
        font-weight: normal;
        font-size: 1.5rem;
        line-height: 1.625rem;
        color: white;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
    }
    
    .school-list-container{
        margin: 1rem 0;
        width: max-content;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        #school-list{
            border: none;
            background: none;
            padding-right: 1rem;
        }

        .dropdown-trigger {
            color: white;
            font-style: normal !important;
            background: linear-gradient(90.39deg, #2B295F 0%, #2E2C66 101.27%);
            padding-left: 1rem;
            border-radius: 0.5rem;
        }

        .caret {
            transform: translateY(-5px) translateX(-5px) !important;
        }

    }

    .chart-keybar-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        height: 100%;
        z-index: 0;

        .chart-wrapper-container {
            width: calc(100% - 50px);
            .chart-wrapper {
                width: 100%;
                padding: 0rem;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                background-color: #080036;
                border-radius: 10px;
                position: relative;
                z-index: 100;
            }
        }
    }

    @media (max-width: $laptopL) {
        .chart-keybar-wrapper {
            .chart-wrapper-container{
                width: calc(100% - 70px);
            }
            .vertical-key-bar-container {
                .key-bar {
                    height: 750px;
                    width: 20px;
                    div {
                        height: 68px;
                    }
                }
                .keys{
                    p{
                        font-size: 22px;
                        line-height: 3;
                    }
                }
            }
        }
    }

    @media (max-width: $laptopM) {
        .chart-keybar-wrapper {
            .chart-wrapper-container{
                width: calc(100% - 50px);
            }
            .vertical-key-bar-container {
                .key-bar {
                    height: 310px;
                    width: 10px;
                    div {
                        height: 28px;
                    }
                }
                .keys{
                    p{
                        font-size: 14px;
                        line-height: 2;
                    }
                }
            }
        }
    }


    @media (max-width: $laptop) {
        .chart-keybar-wrapper {
            .vertical-key-bar-container {
                .key-bar {
                    height: 277px;
                    div {
                        height: 25px;
                    }
                }
            }
        }
    }

    @media (max-width: $tablet) {
        .chart-keybar-wrapper {
            .vertical-key-bar-container {
                .key-bar {
                    height: 255px;
                    div {
                        height: 23px;
                    }
                }
            }
        }
    }

    @media (max-width: $mobileL) {
        padding-left: 1rem;
        padding-right: 1rem;

        .title{
            font-size: 1.1rem;
            line-height: 1.25rem;    
        }
        .chart-keybar-wrapper{
            .chart-wrapper-container {
                .chart-wrapper{
                    padding: 1rem 0rem 1rem;
                    border-radius: 15px !important;
                }       
            }
            .vertical-key-bar-container{
                margin-top: -25px;
                .key-bar{
                    height: 145px;
                    width: 8px;
                    div{
                        height: 13px;
                    }
                }
                .keys{
                    p{
                        font-size: 8px;
                        line-height: 1.7;
                    }
                }
            }
        }
    }

    @media (max-width: $mobileM) {
        padding-left: 0;
        padding-right: 0;
        .title{
            font-size: 0.9rem;
            line-height: 1rem;    
        }
        .chart-keybar-wrapper {
            .chart-wrapper-container {
                .chart-wrapper {
                    border-radius: 10px !important;
                }
            }
        }
    }
}


.tooltip {
    font-family: Poppins;
    font-size: 0.8rem;
    padding: 0.75rem;
    border-radius: 0.5rem;
    background-color: #00000096;
    width: fit-content;
    cursor: default;
    pointer-events: none;
    z-index: 10000000000;

    p{
        color: white !important;
        font-size: 0.75rem !important;
    }

    .tooltip-university{
        font-size: 1rem !important;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 1rem;
    }

    .acceptance-rate{
        display: flex;
        justify-content: center;
        align-items: center;

        p:nth-child(2){
            margin-left: 10px !important;
            color: rgb(189, 189, 189) !important;
        }
    }

    .chart-tooltip-table{
        padding-top: 1rem;
    }

    .tooltip-header, .tooltip-row{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        P{
            margin-left: 1rem;
            &:first-child{
                margin-left: 0%;
            }
        }
    }
}


.chart-wrapper {
    position: relative;
}

.chart-wrapper::after {
    position: absolute;
    content: " ";
    top: 1%;
    right: -2rem;
    width: 0.5rem;
    min-height: 98%;
    border-radius: 2rem;
    background: rgb(255,0,128);
    background: linear-gradient(180deg, rgba(255,0,128,1) 0%, rgba(240,33,160,1) 10%, rgba(214,55,193,1) 20%, rgba(169,65,169,1) 30%, rgba(199,89,189,1) 40%, rgba(197,113,206,1) 50%, rgba(171,141,202,1) 60%, rgba(82,140,177,1) 70%, rgba(82,140,177,1) 80%, rgba(9,101,162,1) 90%, rgba(5,79,145,1) 100%);
}

/*.chart-wrapper::after {
    content: "0";
    color: white;
    text-indent: 1rem;
}*/

/*.chart-wrapper::before {
    content: "100";
    position: absolute;
    text-indent: 1rem;
    right: -4rem;
    color: white;
    bottom: 0rem;
    z-index: 1;
}*/

.chart-wrapper-container {
    position: relative;
}

.max-val {
    width: 3rem;
    text-align: left;
    position: absolute;
    top: 0;
    right: -6rem;
    color: white;
    font-family: "Poppins", sans-serif;
}

.min-val {
    width: 3rem;
    text-align: left;
    position: absolute;
    bottom: 3.5rem;
    right: -6rem;
    color: white;
    font-family: "Poppins", sans-serif;
}

@media (max-width: 450px) {
    .min-val {
        bottom: 10%;
    }
}

@media (max-width: 400px) {

    .min-val, .max-val {
        display: none;
    }
}