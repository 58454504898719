@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

$laptop: 1110px;        // laptop width
$tablet: 770px;         // tablet width
$mobileL: 425px;        // mobile large width 
$mobileM: 375px;        // mobile medium width 
$mobileS: 320px;        // mobile small width 


.prev-cumulative-gpa-wrapper {
    padding: 0.5rem 1rem 0.1rem 1rem;
    // border: 1px solid lighten(#22204C, 10%);
    // background: linear-gradient(123.96deg, #22204C 0%, #272558 100%);
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 0;

    input {
        text-indent: 0 !important;
    }

    $f-size: calc(100% + 2px);
    $b-dev: -1px;

    &::after {
        position: absolute;
        content: " ";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background: linear-gradient(906deg, #22204C 0%, #272558 100%);
        z-index: -1;
    }

    &::before {
        position: absolute;
        top: $b-dev;
        left: $b-dev;
        width: $f-size;
        height: $f-size;
        border-radius: inherit;
        content: " ";
        background: linear-gradient(160.19deg, #625F95 0.72%, rgba(47, 45, 90, 0) 37.95%, #625F95 100%);
        z-index: -1;
    }

    input {
        width: 3.5rem !important;
        text-indent: 0;
    }

    > p {

        font-size: 1.3em;
        font-weight: 500;
        font-family: "Poppins", sans-serif;
        color: white;
        white-space: nowrap;

    }

    .right-section {

        margin: 0;
        
        .prev-gpa-container label,
        .total-credits-container label {

            input {
                height: 3rem;
                box-sizing: border-box;
                border-radius: 0.5rem;
                text-align: center;
            }

            p {
                padding-right: 10px;
                font-size: 1.12em;
                font-family: "Poppins", sans-serif;
                white-space: nowrap;
            }

        }

        .total-credits-container {
            padding-right: 0;

            .valign-wrapper {
                justify-content: flex-end;
            }

        }
    }
}

.total-credits-container {
    margin-bottom: 0;
}

@media (max-width: 600px) {
    
    .right-section {

        .prev-gpa-container, .total-credits-container {
            padding: 0;
            margin: 0;

            label p {
                width: 10rem !important;
                padding-right: 0 !important;
            }

        }

        padding: 1rem 0 !important;

        input {
            // width: 8rem !important;
            width: 100% !important;
        }
    }
}

.poppins-large {
    font-size: 1.2rem;
    font-family: "Poppins", sans-serif !important;
}