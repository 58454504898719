$laptop: 1110px;        // laptop width
$tablet: 770px;         // tablet width
$mobileL: 425px;        // mobile large width 
$mobileM: 375px;        // mobile medium width 
$mobileS: 320px;        // mobile small width 


.buttons-container {

    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .switch-btns-container {
        background: none;

        button {
            height: 2.5rem;
            width: 12.5rem;
            outline: none;
            border: none;
            font-size: 1rem;
            line-height: 1.125rem;
            cursor: pointer;
            background: rgba(255, 255, 255, 0.05);
            color: darken(white, 15%);

            &.active-switch-btn{
                background: #6339FF;
                color: #FFFFFF;
            }

            &.left-switch-btn {
                border-radius: 6.25rem 0 0 6.25rem;
            }

            &.right-switch-btn {
                box-sizing: border-box;
                border-radius: 0 6.25rem 6.25rem 0;
            }            
        }

    }

    .weighted-switch-container {

        color: darken(white, 15%);
        margin-bottom: 0;

        // Switch using css
        .weighted-switch {
            margin-left: 1.875rem;
            position: relative;
            display: inline-block;
            width: 3rem;
            height: 1.25rem;
            transform: translateY(-2px);

            input {
                opacity: 0;
                width: 0;
                height: 0;
            }

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                -webkit-transition: .4s;
                transition: .4s;
                background: rgba(255, 255, 255, 0.05);
            }

            .slider:before {
                position: absolute;
                content: "";
                height: 0.75rem;
                width: 0.75rem;
                left: 0.4rem;
                top: 0.2rem;
                background: #AAAAAA;
                border: none !important;
                transition: .4s;
                z-index: 9;
  
            }
            
            #tick-mark{
                display: inline-block;
                width: 1.875rem;
                height: 1.875rem;
                &::before {
                    left: 10%;
                    top: 62%;
                    height: 25%;
                    width: 2px;
                    background-color: #FFFFFF;
                    z-index: 100;
                    position: absolute;
                    content: "";
                    transform: translateX(10px) rotate(-45deg);
                    transform-origin: left bottom;

                    @media (max-width: $mobileL) {
                        left: 0% !important;
                    }    
    
                }
                &::after {
                    position: absolute;
                    left: 12%;
                    bottom: 10%;
                    height: 2px;
                    width: 25%;
                    background-color: #FFFFFF;
                    z-index: 100;
                    content: "";
                    transform: translateX(10px) rotate(-45deg);
                    transform-origin: left bottom;
                    @media (max-width: $mobileL) {
                        left: 0%;
                    }    
                }    
            }

            input:checked+.slider {
                background: #6339FF;
                background: #6339FF;

            }

            input:checked+.slider:before{
                background: #f8f8f8;
                border: none !important;
            }


            input:checked+.slider:before {
                transform: translateX(22px);

            }

            /* Rounded slider */
            .slider.round {
                border-radius: 6.25rem;
            }

            .slider.round:before {
                border-radius: 50%;
            }
        }
    }
}


.semesters-wrapper{
    padding: 1rem 1.5rem;
    // background: linear-gradient(123.96deg, #22204C 0%, #272558 100%);
    box-sizing: border-box;
    position: relative;
    border-radius: 0.5rem;
    z-index: 0;


    $f-size: calc(100% + 2px);
    $b-dev: -1px;

    &::after {
        position: absolute;
        content: " ";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background: linear-gradient(123.96deg, #22204C 0%, #272558 100%);
        z-index: -1;
    }

    &::before {
        position: absolute;
        top: $b-dev;
        left: $b-dev;
        width: $f-size;
        height: $f-size;
        border-radius: inherit;
        content: " ";
        z-index: -1;
        background: linear-gradient(90deg, #625F95 0.72%, rgba(47, 45, 90, 0) 37.95%, #625F95 100%);
    }


    .bottom-wrapper{
        padding: 1.5rem 0 0.5rem 0;

        .total-credits-container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #00C0FF;
            font-weight: bolder;
            background: rgba(#171432, 0.5);
            padding: 0.5rem 1rem;
            border-radius: 0.5rem;
            * {
                font-family: "Poppins", sans-serif !important;
            }

            p:first-child {
                margin-right: 1rem;
                color: white;
                font-weight: 500;
                // white-space: nowrap;
            }


        }
    }
}

.calculate-cgpa-btn{
    float: right;
    // clear: both;
    padding: 1rem 2rem;
    text-align: center;
    border: solid #00C0FF !important;
    box-sizing: border-box;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    background: none;
    cursor: pointer;
    color: #00c0ff;
    font-family: "Poppins", sans-serif;
    text-transform: initial !important;

    p {
        white-space: nowrap;
    }


    &:hover{
        background-color: #00C0FF;
        p {
            color: white;
        }
    }
}

.clear-float{
    // clear: both;
}




// Media Queries
@media (max-width: 600px) {

    .weighted-switch-container {
        padding: 1rem 0 0 0 !important;
    }

    // .total-credits-container {
    //     margin-top: 1.5rem;
    // }

    .add-btn {
        width: 100%;
        text-align: center !important;
        justify-content: center;
    }

    .calculate-cgpa-btn {
        width: 100 !important;
    }

    .bottom-wrapper {
        .total-credits-container {
            margin-top: 1rem;
        }
    }
    
}

@media (min-width: 600px) {
    .sub-btn-container {
        max-width: 25rem;
    }
}