$mobileL: 425px; // mobile large width 
$mobileM: 375px; // mobile medium width 
$mobileS: 320px; // mobile small width 


.gpa-summary-container {
    margin-top: 5rem;

    .gpa-summary-calculations {
        padding: 1rem 0 0 0;
        border-radius: 0.5rem;
        background: linear-gradient(92.06deg, rgba(138, 6, 215, 0.5) 0%, rgba(99, 57, 255, 0.5) 47.4%, rgba(0, 192, 255, 0.5) 100%);
        display: flex;
        justify-content: space-evenly;
        align-items: baseline;
        flex-wrap: wrap;

        input {
            height: 3rem;
            box-sizing: border-box;
            border-radius: 0.25rem;
            font-weight: 500;
            line-height: 1.125rem;
            text-align: center;
            cursor: initial;

        }

        input.without-ind {
            text-indent: 0;
        }

    }

}

.gradient-border {
    width: inherit;
    height: 10rem;
    position: relative;
    padding: 1rem;
    border-radius: 0.5rem;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
    

    input.required-gpa {
        border: none !important;
        background-color: transparent !important;
    }

}

.summary-col {
    margin-bottom: 0;
    padding: 0 !important;
    width: 20%;
    min-width: 12rem;
    height: 10rem;

    p {
        font-family: "Poppins", sans-serif;
        font-weight: 300 ;
        font-size: 1rem;
    }

}

@media (max-width: 1080px) {
    
    .gpa-summary-calculations {
        padding: 5rem 0 !important;
    } 

    .summary-col {
        width: 50%;
    }

}

@media (max-width: 650px) {
    .summary-col {
        width: 100%;

        .container {
            width: 12rem !important;
            padding: 1rem 0 !important;
        }

        input {
            width: 100% !important;
        }

    }


    .gradient-container {
        height: 15rem;
    }

}

.remainingCredits, .target-gpa {
    border-radius: 0.5rem !important;
    border: none !important;
    width: 6rem !important;
    box-shadow: none !important;
    padding: 0.5rem !important;
}

// .gradient-border::before {
//     position: absolute;
//     z-index: -1;
//     content: " ";
//     $dev: -5px;
//     top: $dev;
//     left: $dev;
//     border-radius: 0.5rem;
//     width: calc(100% + 10px);
//     height: calc(100% + 10px);
//     background: linear-gradient(92.06deg, #30DFED 0%, #6063FE 23.96%, #DD41F2 60.42%, #FE93A4 100%);
// }


@media (max-width: 650px) {
    .summary-col {
        padding: 1rem 0 !important;
        text-align: center !important;
    }
}

@media (min-width: 650px) {

    .summary-col * {
        text-align: left !important;
    }

}


@media (max-width: 650px) {

    .summary-col {
        margin: 2.5rem 0;

        .container {
            width: 65% !important;
        }

    }

    .summary-col:nth-child(4) {

        .gradient-border {
            height: 20rem;
        }
        
    }

    .summary-col .white-text {
        font-size: 1.5em !important;
    }

}


@media (max-width: 520px) {

    .summary-col:nth-child(4) {

        .container {
            width: 70% !important;
        }
        
    }

}


@media (max-width: 430px) {

    .summary-col:nth-child(4) {

        .container {
            width: 80% !important;
        }
        
    }

}


@media (max-width: 340px) {

    .summary-col:nth-child(4) {

        .container {
            width: 90% !important;
        }
        
    }

}