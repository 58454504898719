$laptop: 1110px;        // laptop width
$tablet: 770px;         // tablet width
$mobileL: 425px;        // mobile large width 
$mobileM: 375px;        // mobile medium width 
$mobileS: 320px;        // mobile small width 


.semester-container{
    margin-top: 1rem;
}

.semester-header{
    margin: 0.5rem 0 1.5rem 0;

    .title {
        font-weight: 400;
        font-size: 1.2rem;
        font-family: "Poppins", sans-serif !important;
        color: white;
    }

    .semester-total-wrapper {
        padding-right: 0;
    }

    .semester-total-container{
        padding: 0.5rem 1rem;
        background: rgba(#171432, 0.5);
        border-radius: 0.5rem;
        min-width: 49%;

        * {
            font-family: "Poppins", sans-serif !important;
        }

        > p {
            color: white;
            font-weight: 500;
        }

        .semGPA, .semCred {
            color: #00C0FF;
        }

    }
}

.divider {
    opacity: 0.2;
}

// Media Queries
@media (max-width: 600px) {
    
    .semester-total-wrapper {
        padding: 1rem 0 0rem 0 !important;
    }

}


@media (min-width: 450px) {
    .semester-total-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

@media (max-width: 450px) {
    .semester-total-container > p {
        padding: 0.5rem 0;
    }
}