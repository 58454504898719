$laptop: 1110px;        // laptop width
$tablet: 770px;         // tablet width
$mobileL: 425px;        // mobile large width
$mobileM: 375px;        // mobile medium width
$mobileS: 320px;        // mobile small width

.semester-container input, .prev-cumulative-gpa-wrapper input {
    border-radius: 0.25rem;
    padding: 0.5rem;
    box-shadow: none !important;
    border: 1px solid #2F2D5A;
    background: linear-gradient(90.39deg, #2B295F 0%, #2E2C66 101.27%);
    text-indent: 1rem;
    border-radius: 0.5rem !important;
    color: white;
    font-family: "Poppins", sans-serif;


    &::placeholder {
        font-weight: 400;
        font-style: italic;
        color: grey;
    }
}

.grades-wrapper, .weights-wrapper{
    color: #AAAAAA;

    select {
        border: none;
        font-style: italic;
        background: none;
    }
}

.weights-wrapper{
    padding-right: 0 !important;
}

.dropdown-content {

    max-height: 20rem;
    background: linear-gradient(90.39deg, #2B295F 0%, #2E2C66 101.27%);
    font-family: "Poppins", sans-serif;
    border-radius: 0.5rem;
    overflow-y: scroll;

    li * {
        color: darken(white, 30%) !important;
        font-style: normal;
    }
    
    li:not(.disabled) * {
        color: white !important;
    }


    &::-webkit-scrollbar {
        width: 5px;
        border-radius: 0.75rem;
    }

    &::-webkit-scrollbar-track {
        background: linear-gradient(123.96deg, #22204C 0%, #272558 100%);
        height: 80%;
    }

    &::-webkit-scrollbar-thumb {
        background: #0c0c0c;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #0c0c0c;
        width: 5px;
    }

}

.italic {
    font-style: italic !important;
}

.select-wrapper:not(.white-text) {
    font-weight: 500;
    font-style: italic;
    
    .dropdown-content.select-dropdown li * {
        // font-style: italic;
    }

    .select-dropdown.dropdown-trigger {
        font-style: italic;
    }

}

.select-wrapper input {
    color: grey;
}

.select-wrapper.white-text {
    
    input.select-dropdown {
        color: white !important;
        font-style: normal;
    }

    .dropdown-content.select-dropdown li * {
        // font-style: italic;
    }

}

// custom dropdown styling

.custom-dropdown {
    position: relative;
    height: 3.1rem;
}

@media (max-width: 600px) {
    .custom-dropdown {
        margin-bottom: 0.6rem;
    }
}

.custom-dropdown-trigger {
    background: linear-gradient(90.39deg, #2B295F 0%, #2E2C66 101.27%);
    padding-left: 1rem;
    padding-right: 0.5rem;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;

    span {
        font-size: 1.2rem;
        color: grey;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        text-transform: initial;
        margin-top: 0.2rem;
    }

    .material-icons {
        color: white;
        margin-top: 0.2rem;
    }

    width: 100%;
    border-radius: 0.5rem;
    height: 3rem;
}

.custom-dropdown-content {

    min-width: fit-content;
    width: 100%;
    background: linear-gradient(90.39deg, #2B295F 0%, #2E2C66 101.27%);
    position: absolute;
    z-index: 1;
    border-radius: 0.5rem;
    height: 15rem;
    overflow-y: scroll;

    li {
        padding: 1rem 0.8rem;
        cursor: pointer;
        font-size: 1.2rem !important;
        font-family: "Poppins", sans-serif;
        color: white;
        &:hover {
            background-color: rgba($color: #000000, $alpha: 0.2);
        }

    }

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: linear-gradient(90.39deg, #2B295F 0%, #2E2C66 101.27%);
    }

    &::-webkit-scrollbar-thumb {
        background: black;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }


}

.custom-dropdown-content:not(.hide) {
    animation: growInSize 0.15s ease-in-out;
    transition: 0.15s ease-in-out;
}

.custom-dropdown-content.hide {
    transition: 0.15s ease-in-out;
}

@keyframes growInSize {
    0% {
        transform: scale(0);
        transform-origin: 0 0;
    }
    100% {
        transform: scale(1);
        transform-origin: 0 0;

    }
}


@media (max-width: 575px) {

    .course-row {
        input::placeholder {
            font-weight: 400;
            font-style: italic;
            color: grey;
            // font-size: 2.5vw;
        }

        input {
            text-indent: 0.5rem;
            // font-size: 2.5vw !important;
        }

    }

    .custom-dropdown-trigger {
        padding-left: 0.5rem;
    }

    .custom-dropdown-trigger .dd-chevron {
        // font-size: 3.5vw;
    }

    .custom-dropdown-trigger span {
        // font-size: 3vw;
    }

    .custom-dropdown-trigger .material-icons {
        margin-left: 0;
    }

    .custom-dropdown-content li {
        // font-size: 2.5vw !important;
    }

}