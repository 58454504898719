.artwork {
    margin-top: 5rem;
}


.artwork-draw-section {
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem;
    background-color: #21242b;
    // aspect-ratio: 16/9 !important;
    // object-fit: contain !important;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    .cross-icon {
        position: absolute;
        top: 2rem;
        right: 2rem;
        font-size: 1rem !important;
        color: white;
        opacity: 0.7;
        cursor: pointer;
        transition: all 0.3s ease;
        z-index: 99999999;
    }

        canvas {
            position: absolute;
            border-radius: 0.5rem;
            width: 100% !important;
            height: auto !important;
        }
    }


// @media (max-width: 700px) {
//     .not-fullscreen .artwork-credits {
//         font-size: 1.2vw !important;
//     }
// }

@media (min-width: 700px) {
    .not-fullscreen .artwork-credits {
        font-size: 0.8rem !important;
    }
    
    .full-screen .artwork-credits {
        font-size: 0.8rem !important;
    }
}

@media (max-width: 700px) {
    .artwork-credits {
        font-size: 1.5vw !important;
    }
}

@media (max-width: 600px) {

    .artwork-draw-section{
        canvas{
            width: 70% !important;
        }
    }
}


.not-fullscreen, .landscape{
    canvas{
        top: 50% !important;
        transform: translateY(-50%) !important;
        -webkit-transform: translateY(-50%) !important;
        -moz-transform: translateY(-50%) !important;
        -ms-transform: translateY(-50%) !important;
        -o-transform: translateY(-50%) !important;
    }
}


.artwork-footer {
    margin-top: 0.5rem;
    .artwork-credit {
        font-size: 0.8rem;
    }

    .share-video-section {
        p {
            font-size: 0.95rem;
        }
        i {
            font-size: 1.5rem;
            margin: 0 0.7rem;
            cursor: pointer;
        }
    }

}

@media (max-width: 600px) {
    .share-video-section {
        margin: 1rem 0 0 0;
    }

    .artwork-controls {
        height: 2.5rem !important;

        i {
            font-size: 1rem !important;
        }
    }

    .full-screen {
        .artwork-controls {
            height: 3.5rem !important;

            i {
                font-size: 1.3rem !important;
            }
        }
    }
}

.progress {
    width: 10rem;
    background: linear-gradient(92.06deg, rgba(138, 6, 215, 0.5) 0%, rgba(99, 57, 255, 0.5) 47.4%, rgba(0, 192, 255, 0.5) 100%);
}

.indeterminate {
    background: #6339FF !important;
}

.artwork-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0%;
    left: 0%;
    width: 100%;
    z-index: 9999999;
    background: rgba(0, 0, 0, 0.3);
    height: 4rem;
    padding: 0 2rem !important;
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;

    i {
        color: white;
        cursor: pointer;
        font-size: 1.5rem;

        &:hover {
            opacity: 0.5 !important;
        }
    }

    &.hide {
        display: none;
    }

}

.full-screen {
    position: fixed !important;
    padding: 0 !important;
    margin: 0 !important;
    left: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    overflow: visible !important;


    width: 100% !important;
    min-width: 100% !important;
    z-index: 999999 !important;
    border-radius: 0% !important;
    height: 100%;
    min-height: 100%;

    &.artwork-draw-section{
        canvas {
            border-radius: 0 !important;
        }
    }
}

.artwork-credits {
    text-align: right;
    position: absolute;
    bottom: 2%;
    right: 2%;
    background: none;
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem;
    opacity: 0.8;
    color: white;

    * {
        color: inherit;
        font-weight: 500;
        font-family: "Poppins", sans-serif;
        text-shadow: 1px 1px black;
    }

    a:hover {
        text-decoration: underline;
    }
}

.artwork-credits a,
.artwork-credit a {
    text-decoration: "none";
    font-weight: "bold" !important;

    &:hover {
        text-decoration: underline;
    }
}

.bold{
    font-weight: bold;
}

.share-art-cta {
    font-family: "Poppins", sans-serif !important;
}

.animated-facebook {
    animation: hop 0.4s ease-in-out;
    animation-delay: 1s;
}


.animated-twitter {
    animation: hop 0.4s ease-in-out;
    animation-delay: 1.2s;
}

.animated-pinterest {
    animation: hop 0.4s ease-in-out;
    animation-delay: 1.4s;
}

.animated-link {
    animation: hop 0.4s ease-in-out;
    animation-delay: 1.6s;
    margin-right: 0 !important;
}


@keyframes hop {

    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-0.5rem);
    }

    90% {
        transform: translateY(0.1rem);
    }

    100% {
        transform: translateY(0px);
    }

}

.artwork{
    position: relative;
}

.modal {
    background: none !important;

    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
    

    overflow: hidden !important ;
    border-radius: 0.5rem !important;
    max-height: 100% !important;
    width: 80% !important;
    .modal-content{
        padding: 0% !important;
    }
}

.artwork-with-text{
    overflow: hidden;
}


@media (max-width: 500px){
    .full-screen .artwork-credits{
        p, a{
            font-size: 2vw !important;
        }
    }
}

@media (max-width: 650px){
    .artwork-controls,
    .full-screen .artwork-controls{
        height: 5rem !important;
        i{
            font-size: 2rem !important;
        }
    }
}


.modal-overlay{
    opacity: 1 !important;
}

