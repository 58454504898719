
body {
    position: relative;
}

.full-screen{
    bottom: 0% !important;
    right: 0% !important;
}

.cta-btn-container {
    width: 20rem;
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 9999999;

    font-family: "Poppins", sans-serif !important;

    a.btn-flat {

        border-radius: 2rem;
        color: white;
        padding: 0rem 0rem !important;
        text-transform: initial !important;
        margin-top: 1rem !important;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 80%;
        flex-direction: column;
        
        &:hover {
            filter: brightness(90%);
        }
    }

}



@media (max-width: 600px) {
    .artwork-draw-section{
        canvas{
            width: 100% !important;
        }
    }
}


@media (max-width: 380px){
    .full-screen .artwork-credits{
        div, a{
            font-size: 2vw;
        }
    }
}
