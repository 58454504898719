$tablet: 770px;         // tablet width
$mobileL: 425px;        // mobile large width 
$mobileM: 375px;        // mobile medium width 
$mobileS: 320px;        // mobile small width 


.current-cumulative-gpa-container{
    width: auto;
    border-radius: 0.5rem;
    background: linear-gradient(92.06deg, rgba(138, 6, 215, 0.5) 0%, rgba(99, 57, 255, 0.5) 47.4%, rgba(0, 192, 255, 0.5) 100%);
    margin: 1.5rem 0;
    vertical-align: middle;
    border: 1px solid #2F2D5A;
    overflow: hidden;

    strong em {
        font-family: "Poppins", sans-serif;
        font-weight: bolder;
    }

    .description-wrapper{

        padding: 1rem 1.5rem;

        p {
            font-size: 1.5rem;

            // @media (max-width: 1060px) {
            //     font-size: 2vw;
            // }

            &.cgpa-description{
                font-size: 0.9rem;
                font-weight: lighter;
                max-width: 30rem;
            }

        }

    }

    .description-wrapper-title {
        margin-bottom: 0.5rem;
    }

    .cgpa-container{

        margin: 0;
        padding: 2rem;
        position: relative;
        
        * {
            font-family: "Poppins", sans-serif;
        }

        p{
            font-size: 1.5rem;
            // color: darken(white, 25%) !important;
        }
        h1{
            font-size: 2.5rem;
            font-weight: 500;
        }
        
        
        
    }

}

.cgpa-container::before {
    content: " ";
    width: 1px;
    height: 300%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #2F2D5A;
}

@media (max-width: 600px) {

    .cgpa-container {
        padding-left: 1rem !important;
    }

    .cgpa-container::before {
        display: none;
    }

}


span.line {

    @media (min-width: 810px) {
        display: inline-block;
    }
}