.pause {
    -webkit-animation-play-state: paused;
    animation-play-state: paused
}

.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite
}

.double {
    -webkit-animation-iteration-count: 2;
    animation-iteration-count: 2
}

.triple {
    -webkit-animation-iteration-count: 3;
    animation-iteration-count: 3
}

.reverse {
    -webkit-animation-direction: reverse;
    animation-direction: reverse
}

.alternate {
    -webkit-animation-direction: alternate;
    animation-direction: alternate
}

.alternate-reverse {
    -webkit-animation-direction: alternate-reverse;
    animation-direction: alternate-reverse
}

.a1 {
    -webkit-animation-duration: .15s;
    animation-duration: .15s
}

.a2 {
    -webkit-animation-duration: .3s;
    animation-duration: .3s
}

.a3 {
    -webkit-animation-duration: .45s;
    animation-duration: .45s
}

.a4 {
    -webkit-animation-duration: .6s;
    animation-duration: .6s
}

.a5 {
    -webkit-animation-duration: .75s;
    animation-duration: .75s
}

.a6 {
    -webkit-animation-duration: .9s;
    animation-duration: .9s
}

.a-1 {
    -webkit-animation-duration: 1s;
    animation-duration: 1s
}

.a-2 {
    -webkit-animation-duration: 2s;
    animation-duration: 2s
}

.a-3 {
    -webkit-animation-duration: 3s;
    animation-duration: 3s
}

.d1 {
    -webkit-animation-delay: .15s;
    animation-delay: .15s
}

.d2 {
    -webkit-animation-delay: .3s;
    animation-delay: .3s
}

.d3 {
    -webkit-animation-delay: .45s;
    animation-delay: .45s
}

.d4 {
    -webkit-animation-delay: .6s;
    animation-delay: .6s
}

.d5 {
    -webkit-animation-delay: .75s;
    animation-delay: .75s
}

.d6 {
    -webkit-animation-delay: .9s;
    animation-delay: .9s
}

.d-1 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s
}

.d-2 {
    -webkit-animation-delay: 2s;
    animation-delay: 2s
}

.d-3 {
    -webkit-animation-delay: 3s;
    animation-delay: 3s
}

.ease {
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease
}

.ease-in {
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in
}

.ease-in-quad {
    -webkit-animation-timing-function: cubic-bezier(.55, .085, .68, .53);
    animation-timing-function: cubic-bezier(.55, .085, .68, .53)
}

.ease-in-cubic {
    -webkit-animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19)
}

.ease-in-quart {
    -webkit-animation-timing-function: cubic-bezier(.895, .03, .685, .22);
    animation-timing-function: cubic-bezier(.895, .03, .685, .22)
}

.ease-in-quint {
    -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06)
}

.ease-in-expo {
    -webkit-animation-timing-function: cubic-bezier(.95, .05, .795, .035);
    animation-timing-function: cubic-bezier(.95, .05, .795, .035)
}

.ease-in-circ {
    -webkit-animation-timing-function: cubic-bezier(.6, .04, .98, .335);
    animation-timing-function: cubic-bezier(.6, .04, .98, .335)
}

.ease-out {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out
}

.ease-out-quad {
    -webkit-animation-timing-function: cubic-bezier(.25, .46, .45, .94);
    animation-timing-function: cubic-bezier(.25, .46, .45, .94)
}

.ease-out-cubic {
    -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1)
}

.ease-out-quart {
    -webkit-animation-timing-function: cubic-bezier(.165, .84, .44, 1);
    animation-timing-function: cubic-bezier(.165, .84, .44, 1)
}

.ease-out-quint {
    -webkit-animation-timing-function: cubic-bezier(.23, 1, .32, 1);
    animation-timing-function: cubic-bezier(.23, 1, .32, 1)
}

.ease-out-expo {
    -webkit-animation-timing-function: cubic-bezier(.19, 1, .22, 1);
    animation-timing-function: cubic-bezier(.19, 1, .22, 1)
}

.ease-out-circ {
    -webkit-animation-timing-function: cubic-bezier(.075, .82, .165, 1);
    animation-timing-function: cubic-bezier(.075, .82, .165, 1)
}

.ease-in-out {
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out
}

.ease-in-out-quad {
    -webkit-animation-timing-function: cubic-bezier(.455, .03, .515, .955);
    animation-timing-function: cubic-bezier(.455, .03, .515, .955)
}

.ease-in-out-cubic {
    -webkit-animation-timing-function: cubic-bezier(.645, .045, .355, 1);
    animation-timing-function: cubic-bezier(.645, .045, .355, 1)
}

.ease-in-out-quart {
    -webkit-animation-timing-function: cubic-bezier(.77, 0, .175, 1);
    animation-timing-function: cubic-bezier(.77, 0, .175, 1)
}

.ease-in-out-quint {
    -webkit-animation-timing-function: cubic-bezier(.86, 0, .07, 1);
    animation-timing-function: cubic-bezier(.86, 0, .07, 1)
}

.ease-in-out-expo {
    -webkit-animation-timing-function: cubic-bezier(1, 0, 0, 1);
    animation-timing-function: cubic-bezier(1, 0, 0, 1)
}

.ease-in-out-circ {
    -webkit-animation-timing-function: cubic-bezier(.785, .135, .15, .86);
    animation-timing-function: cubic-bezier(.785, .135, .15, .86)
}