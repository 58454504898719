$tablet: 770px;         // tablet width
$mobileL: 425px;        // mobile large width 
$mobileM: 375px;        // mobile medium width 
$mobileS: 320px;        // mobile small width 


.main-page-container 
{

    .footer-q-container {
        display: block;
        .footer-question {
            padding-top: 2rem;
        }
    }

    .footer-question, .footer-question * {
        font-size: 2.25rem;
    }

    .footer-question {
        margin: auto;
        text-align: center;
        user-select: none;
        // margin-top: 3.5rem;
        // margin-bottom: 3rem;
        padding-bottom: 2rem;
        max-width: 45rem;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        color: white;
        line-height: 1.75 !important;
        position: relative;
        display: block;
        
        span {
            font-family: "Poppins", sans-serif;
            font-weight: bold;

            .rainbow {
                background: linear-gradient(92.06deg, #30DFED 0%, #6063FE 23.96%, #DD41F2 60.42%, #FE93A4 100%);
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

    }

    .discover-how-btn {
        margin: auto;
        margin-bottom: 3rem;
        padding: 0.8rem 3rem;
        text-align: center;
        border: solid 1px;
        box-sizing: border-box;
        border-radius: 4rem;
        display: flex;
        align-items: center;
        background: linear-gradient(90.06deg, #30DFED 0%, #6063FE 23.96%, #DD41F2 60.42%, #FE93A4 100%);
        transition: 0.3s ease-in-out;
        cursor: pointer;
        position: relative;
        outline: none !important;
        border: none !important;
        // z-index: 3;

        .discover-line {
            display: block;
            white-space: nowrap;
        }

        &:hover {
            transition: 0.3s ease-in-out;
            background: linear-gradient(150.06deg, #30DFED 0%, #6063FE 23.96%, #DD41F2 60.42%, #FE93A4 100%);
        }
        p {
            font-size: 1.875rem;
            font-weight: 500;
            font-family: "Poppins", sans-serif;
            color: white;
        }
    }


    /*
        ReCaptcha Style
    */
    .recaptcha-container {
        margin-top: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    /*
        Error Message Style Here
    */
    .summary-error-msg-container {
        
        margin: 1rem 0;

        .summary-error-msg {
            padding: 1rem;
            text-align: center;
            width: 100%;
            background-color: white;
            border-radius: 0.5rem;
            background: linear-gradient(90.06deg, #30DFED 0%, #6063FE 23.96%, #DD41F2 60.42%, #FE93A4 100%);
            color: white;
        }
        
    }
}


input.dropdown-trigger {
    border: none !important;
    z-index: 0 !important;
}

.caret {
    filter: invert(100%) sepia(96%) saturate(0%) hue-rotate(78deg) brightness(102%) contrast(101%) !important;
    pointer-events: none !important;
    transform: scale(0.75) translateY(5px);
    transform-origin: 0px 0px;

    path {
        d: path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z') !important;
    }

}

.select-dropdown {
    z-index: 1 !important;
}

.gpa-summary-calculations {
    margin-bottom: 1rem !important;
}

.gpa-summary-calculations .gpa-summary-close-btn {
    display: none;
}

.gpa-summary-calculations-invalid {
    position: relative;

    .gpa-summary-close-btn {
        position: absolute;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
        display: block;
        z-index: 2;
    }

}

.gpa-summary-calculations-invalid::before {
    content: "Sorry, this target GPA is not possible given your remaining credits.";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(65.06deg, #30DFED 0%, #6063FE 23.96%, #DD41F2 60.42%, #FE93A4 100%);
    border-radius: 0.5rem;
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 2rem;
    padding: 5rem;
    z-index: 1;
}

.desktop-cta {
    position: relative;
    z-index: 0 !important;
}

.desktop-cta::before {
    position: absolute;
    content: " ";
    width: 60%;
    height: auto;
    aspect-ratio: 1/1;
    background: radial-gradient(50% 50% at 50% 50%, rgba(88, 84, 158, 0.6) 10%, rgba(88, 84, 158, 0.3) 50%, rgba(26, 24, 62, 0) 100%);
    border-radius: 50%;
    z-index: -1;
    top: -50%;
    opacity: 0.7;
    pointer-events: none;
}

.mobile-cta {
    position: relative;
    z-index: 0;
}

.mobile-cta-circle {
    position: absolute;
    width: 20rem;
    height: 20rem;
    background: radial-gradient(50% 50% at 50% 50%, rgba(88, 84, 158, 0.6) 10%, rgba(88, 84, 158, 0.3) 50%, rgba(26, 24, 62, 0) 100%);
    border-radius: 50%;
    z-index: -1;
    top: calc(50% - 10rem);
    left: calc(50% - 10rem);
    opacity: 0.7;
}

.recaptcha-container{
    width: 100%;
    margin-top: 8rem !important;
    display: flex;
    justify-content: center;
    align-content: center;
}

@media (max-width: 640px) {

    .footer-question {
        padding-top: 2rem !important;
    }
    
    .main-page-container {
        .mobile-cta, .desktop-cta, .mobile-cta *, .desktop-cta * {
            @media (max-width: 598px) {
                font-size: 7vw;
            }
        }

    }

    .main-page-container .footer-question::before {
        width: 60vw;
        left: 15vw;
        height: auto;
        aspect-ratio: 1/1;
    }

    .discover-how-btn {
        width: 80vw;
        height: auto;
        justify-content: center;
        align-items: center;
        padding: 10px 20px !important;

        p {
            // white-space: nowrap;
            font-size: 7vw !important;
        }

    }
}


.footer-question {

    .cta-line {
        font-weight: normal !important;

        @media (max-width: 600px) {
            display: block;
        }

    }

}